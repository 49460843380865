

/* .footer {
  text-align: center;
}

.footer {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
} */

.posCenter {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.subPosCenter {
  position: relative;
  margin-left: 41%;
}

.container {
    max-width: 600px;
    margin: auto;
}

/* @keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
} */

