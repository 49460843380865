html, body {
  margin: 0;
  background-color: #8413F5;
  /* height: 1; */
}
.user-payment-page {
  background-color: #8413F5;
}
.user-payment-page p,
.user-payment-page a
{
  font-family: graphik, sans-serif !important;
}

@media (min-width: 940px) {
  .user-payment-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .brand {
    text-align: center;
    margin-bottom: 3rem;
  }
  .loading-container {
    display: flex;
    justify-content: center;
  }
  .card.user-info {
    padding: 3rem 2rem;
    display: flex;
    justify-content: center;
    background-color: transparent !important;
    box-shadow: none !important;
    /* border: 0.5px solid white;
    border: 0.5px dashed white; */
  }

  .not-found-container {
    text-align: center;
  }
  .not-found-heading {
    text-align: center;
    font-size: 1.8em;
    font-weight: 400;
    color: black;
    margin-top: 1rem;
  }
  .tag-not-found {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: black;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .tag-not-found-explainer {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: black;
  }
  .links-container {
    text-align: center;
  }
  .links-container a {
    margin-top: 1.5rem;
    display: inline-block;
    background-color: #9F3FFF;
    padding: 10px 30px;
    color: white;
    border: 2px solid white;
    border-radius: 40px;
  }
  .links-container a:hover {
    color: white;
    opacity: 0.91;
  }
  .links-container a img {
    margin-right: 10px;
  }
  .user-name {
    text-align: center;
    font-size: 1.5em;
    font-weight: 900;
    color: white;
    margin-bottom: 0;
  }
  .user-tag {
    text-align: center;
    font-weight: 300;
    font-size: 1.3em;
    color: #504B4B;
    color: white;
  }
  .qr-container {
    margin-top: 2rem;
    margin-bottom: 1.8rem;
    padding: 10px 10px 5px 10px;
    background-color: white;
    border-radius: 5px;
  }
  .page-card {
    border-radius: 4px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 5%), 0 0 0 1px rgb(63 63 68 / 10%);
    background-color: #FFFFFF;
  }
  .centered-container {
    width: 500px;
  }
  .scan-cta {
    text-align: center;
    font-weight: 300;
    font-size: 1.3em;
    color: #504B4B;
    color: white;
  }
  .pay-button-container {
    display: none;
  }
}

@media (max-width: 939px) {
  .user-payment-page {
    display: flex;
    justify-content: center;
    align-items: space-between;
    height: 95vh;
    color: white;
    text-align: center;
    padding-top: 1.5rem;
  }
  .page-card {
    background-color: #8413F5 !important;
    box-shadow: none !important;
    border-width: 0 !important;
  }
  .qr-container {
    display: none;
  }
  html, body {
    background-color: #8413F5 !important;
  }

  .brand {
    margin-bottom: 5rem;
  }
  .links-container {
  }
  .links-container a {
    margin-top: 1.5rem;
    display: inline-block;
    background-color: transparent;
    padding: 10px 30px;
    color: white;
    border: 0px dashed #ad9999;
    border-radius: 40px;
  }
  .links-container img {
    margin-right: 10px;
  }
  .links-container a:hover {
    color: white;
    opacity: 0.91;
  }
  .avatar-container img {
    width: 130px !important;
    height: 130px !important;
  }
  .user-name {
    text-align: center;
    font-size: 1.7em;
    font-weight: 900;
    margin-bottom: 0;
  }
  .user-tag {
    text-align: center;
    font-weight: 300;
    font-size: 1.4em;
  }
  .pay-button-container {
    display: block;
    margin-top: 2rem;
  }
  .centered-container {
    width: 90%;
  }
  .pay-button {
    margin-top: 1.5rem;
    display: inline-block;
    background-color: #9F3FFF;
    padding: 15px 30px;
    color: white;
    border: 2px solid white;
    border-radius: 40px;
    font-size: 1.02rem;
  }
  .scan-cta {
    display: none;
  }
}