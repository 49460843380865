body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}

@font-face {
  font-family: graphik;
  src: url(./assets/fonts/graphik_medium.woff);
  font-weight: bold;
}

@font-face {
  font-family: graphik;
  src: url(./assets/fonts/graphik_regular.woff);
}

.UserVolumeStyle span {
  font-size: "1.2em";
  font-weight: 600px;
}

.UserVolumeLabel span {
  font-weight: "lighter";
  color: "grey";
}

.preferenceOn {
  color: green;
  font-weight: 600;
}

.preferenceOff {
  color: red;
  font-weight: 600;
}

.MobileMoneyLink:hover {
  text-decoration: underline;
  cursor: pointer;
}

.MobileMoneyLink {
  padding: 0px 8px;
}

.UnlinkedMobileMoney {
  text-decoration: line-through;
  color: grey !important;
}

.customContainerStyle {
  position: relative;
  top: -250px;
  bottom: 200px;
}

.customBodyStyle {
  position: relative;
  top: 0px;
  bottom: 0px;
}

.scanlabel {
  color: #5433bf;
}
/* 
  input[type=text] {
    width: 70%;
    padding: 12px 30px;
    margin: 4px 10px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 8px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
    font-size: 125%;
    font-weight: 600;
  }*/

/* input[type=text]:focus {
    border: 2px solid #572AC5;
  }  */

/* .phoneInputContainer {
    background-color: white;
    border: 0.1px solid #999;
    box-shadow: 0.25px 0.25px 0.5px 0.5px #777;
    margin: auto 0;
  } */

/* .phoneInput {
    width: 70%;
    background-color: white;
    padding: 15px 30px;
    margin: 4px 10px;
    box-sizing: border-box;
    border-radius: 8px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
    font-size: 125%;
    font-weight: 600;
  } */

/* .phoneInput:focus {
    border: 2px solid #572AC5;
  } */

@media (max-width: 940px) {
  .tagBackground {
    position: absolute;
    background-image: linear-gradient(130deg, #5c13f5, #5c13f5);
    right: 0%;
    left: 0%;
    bottom: -30%;
    top: 0%;
  }

  .qr_display {
    display: none;
  }

  input[type="text"] {
    width: 100%;
    padding: 20px 30px;
    margin: 4px auto;
    box-sizing: border-box;
    border: 0.1px solid #999;
    border-radius: 8px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    /* outline: none; */
    font-size: 80%;
    font-weight: 500;
    box-shadow: 0.25px 0.25px 0.5px 0.5px #777;
  }

  .phoneInput {
    width: 100%;
    background-color: white;
    padding: 15px 30px;
    margin: 4px auto;
    box-sizing: border-box;
    /* border: 2px solid #572AC5; */
    border-radius: 8px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
    font-size: 80%;
    font-weight: 500;
  }

  .guestconfirm {
    background-color: #572ac5;
    width: 80%;
    color: #fff;
    padding: 20px;
    padding-left: 80px;
    padding-right: 80px;
    border-radius: 32px;
    text-align: center;
    font-weight: 700;
  }

  .countries {
    margin-left: -10%;
  }
}

@media (min-width: 940px) {
  .tagBackground {
    position: absolute;
    background-image: linear-gradient(130deg, #5c13f5, #5c13f5);
    right: 0%;
    left: 0%;
    bottom: 0%;
    top: 0%;
  }

  /* .qr_display {
      display: none;
      background-color: #000;
    } */

  input[type="text"] {
    width: 80%;
    padding: 20px 30px;
    margin: 4px auto;
    box-sizing: border-box;
    border: 0.1px solid #999;
    border-radius: 8px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    /* outline: none; */
    font-size: 80%;
    font-weight: 500;
    box-shadow: 0.25px 0.25px 0.5px 0.5px #777;
  }
  .phoneInput {
    width: 80%;
    background-color: white;
    padding: 15px 30px;
    margin: 4px auto;
    box-sizing: border-box;
    border: 0.1px solid #999;
    border-radius: 8px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
    font-size: 80%;
    font-weight: 500;
  }

  .guestconfirm {
    background-color: #572ac5;
    width: 80%;
    color: #fff;
    padding: 20px;
    padding-left: 80px;
    padding-right: 80px;
    border-radius: 32px;
    text-align: center;
    font-weight: 700;
  }

  #textmomo {
    margin: 40px 0px;
  }

  #textscantopay {
    margin: 40px 0px;
  }
}

@media screen and (min-color-index: 0) and(-webkit-min-device-pixel-ratio:0) {
  /*
        Define here the CSS styles applied only to Safari browsers
        (any version and any device)
       */
  .safariQR {
    box-shadow: 5px 5px 10px 10px #777;
  }
}

.guestFields {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

#guestForm {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

#QRCode {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

#guestFields {
  opacity: 0;
}

#backQR {
  font-weight: 900;
  cursor: pointer;
}

.guestconfirm:hover {
  background: linear-gradient(130deg, #3479f6, #572ac5);
}

.availableCountries {
  font-weight: bold;
  align-content: center;
  margin: 0;
  padding: 0;
}

.countries {
  -webkit-transition: 0.15s;
  transition: 0.15s;
  /* margin-left: -10%; */
}

#countries {
  display: block;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.errorState {
  font-size: "0.8em";
  text-align: "center";
  color: red;
  font-weight: 600;
  font-size: 0.75em;
}

.successState {
  font-size: "0.8em";
  text-align: "center";
  color: green;
  font-weight: 600;
  font-size: 0.75em;
}

/* @media (max-width: 760px) { */
.scantopay {
  display: block;
  text-align: center;
}

.box {
  position: relative;
}

/* 50% {color: blue; left:200px; top:200px}
    75% {color: green; left:0px; top:200px}
    100% {color: red; left:0px; top:0px}
  } */

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
